import { gql } from '../graphql/__generated__';

export const loggedInUserQuery = gql(`query loggedInUser {
  me {
		id
    email
    firstName
    lastName
    troonRewardsId
    troonCardId
    card: oldTroonCardGroup {
      id
      name
    }
    role
  }
  userRewards {
    availablePoints
    level
  }
  activeTroonCardSubscription {
    status
    name: subscriptionName
    nextInvoiceDate {
    	...DayTime
    }
  }
}`);
